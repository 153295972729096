* {
  box-sizing: border-box;
  font-family:"Noto Sans" !important
}

body {
  margin: 0;
  padding: none;
  box-sizing: border-box;
  color: black !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-green{
  color:#A2C52D
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red !important;
  opacity: 1; /* Firefox */
}
.dropdown-menu{
  background-color: none !important;
}
.MuiInputLabel-root {
  color: black !important;
}

.MuiInput-input {
  color: black !important;
}
.daterangepicker::before  {
  display: none !important;
}
.daterangepicker::after  {
  display: none !important;
}
.text-smaller{
  font-size: smaller;
}
.scroll-barx{
  overflow-x: scroll;
}
.grey {
  /* color: #8f8f8f; */
  color: black !important;
}


.darkgrey {
  /* color: #555555; */
}

.bggrey {
  background-color: #8f8f8f;
}
.w-20{
  width: 20% !important;
}
.color-red{
  color: red !important;
}
.comp-change {
  height: 100%;
  background-color: #efefef;
  width: 100%;
  overflow: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.comp-change::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.compon {
  height: 100vh;
  overflow: hidden !important;  
}

.whole {
  box-sizing: border-box;
  min-height: 100%;
  max-height: 100%;
}

.grid {
  padding-top: 0 !important;
}

.lightblue {
  background-color: #00d0c2 !important;
}

.blue {
  background-color: #4aa9ef !important;
}

.orange {
  background-color: #ef854b !important;
}

.yellow {
  background-color: #eec84b !important;
}

.textlightblue {
  color: #00d0c2 !important;
}

.textblue {
  color: #4aa9ef !important;
}

.textorange {
  color: #ef854b !important;
}

.textyellow {
  color: #eec84b !important;
}

.textgreen {
  color: #a1c335;
}
.text-red{
  color: #f52626;
}

.pag-icon {
  border-radius: 20px;
  padding: 3px 5px;
  font-size: 20px;
  margin: 0 15px 0 15px;
}

.pag-icon-next {
  border-radius: 20px;
  padding: 3px 5px;
  font-size: 20px;
}

.export-excel {
  border: none !important;
  background-color: #efefef;
}

.formTextInput:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.dropdon:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey !important;
  opacity: 1;
  /* Firefox */
}

.daterangeinput {
  width: 90%;
}

.MuiBreadcrumbs-root {
  font-size: 19px !important;
  /* border: 2px solid red; */
}

.brud>a {
  font-size: 20px !important;
  font-weight: bolder !important;
}

.bg-green {
  background-color: #a2c52d !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}
.font-size-12{
  font-size: 12px;
}
.font-size-34 {
  font-size: 34px !important;
}
.font-size-24{
  font-size: 24px ;
}
.font-size-20{
  font-size: 20px;
}
.font-size-30{
  font-size: 30px;
}
.font-size-130{
  font-size: 130px;
}
.font-size-50{
  font-size: 50px;
}
.font-13{
  font-size: 13px;
}.font-11{
  font-size: 11px;
}
.w-15{
  width: 15%;
}



@media screen and (max-width: 1400px) {
  .font-size-20{
    font-size: 16px;
  }

}