.font-weight-bold {
  font-weight: bold;
}
.mechinesetting {
  height: 100% !important;
  border-radius: 15px !important;
  box-sizing: border-box !important;
}
.font-size-24 {
  font-size: 24px;
}
.settingvalues {
  padding-top: 12px;
  font-size: 13px;
}
.lightgrey {
  background-color: #f8f8f8;
}
.insidehedervalue {
  border-radius: 14px 14px;
}
.bor-14 {
  border-radius: 14px;
}
.calibration {
  padding: 11px 20px 11px 20px !important;
}
.linebar {
  text-align: center;
  /* padding-bottom: 14px; */
  display: flex;
  align-items: center;
  font-size: 36px;
  line-height: 10px;
}
.hoel {
  margin-bottom: 120px;
}
.gensetting {
  font-size: 19px !important;
}

.example-spacer {
  flex: 1 1 auto;
}
#shiftTimingId > .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

@media screen and (max-width: 1400px) {
  .font-weight-bol {
    font-size: 15px !important;
  }
  .font-15 {
    font-size: 15px;
  }
  .gensetting {
    font-size: 15px !important;
  }
  .font-12 {
    font-size: 13px !important;
  }
  .MuiSelect-select.MuiSelect-select {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1650px) {
  .font-weight-bol {
    font-size: 15px !important;
  }
  .font-15 {
    font-size: 15px;
  }
  .gensetting {
    font-size: 15px !important;
  }
  .font-12 {
    font-size: 13px !important;
  }
  .MuiSelect-select.MuiSelect-select {
    font-size: 13px !important;
  }
}
