.sidenavheder {
  display: flex;
  justify-content: center;
  padding: 13px 18px;
  
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #1168df;
  position: sticky;
  top: 0;
  width: 100%;
}
.sidenavcollaps {
  height: 100vh;
  /* position: fixed; */
  width: 73px;
}
.collapsIcon {
  height: 40px;
}
.sidenavheder > p {
  padding: none;
  font-family:  "Anton", sans-serif !important;
  margin: 0;
  font-size: 36px;
  /* font-weight: bold; */
}
.sidenavbar {
  height: 100vh;
  /* position: fixed; */
  bottom: 0;
  top: 0;
  width: 300px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  
 
}
.sidenavbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.sidenav1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidenavlist1 {
  padding: 0 10px;
  /* overflow: scroll; */
}
.sidenavlist {
  display: flex;
  justify-content: left;
  padding: 13px;
  /* color: #919191; */
  margin-right: 20px;
  width: 100%;
}
.sidenavlist:hover {
  cursor: pointer;
  color: #1168df;
  width: 100%;
  background-color: #e6f2fd;
  border-radius: 10px;
}
.icon {
  display: flex;
  align-items: center;
  width: 24px;
}
.sidenavName {
  display: flex;
  align-items: center;
  padding-left: 13px !important;
}
#active {
  color: #1168df;
  background-color: #e6f2fd;
  border-radius: 10px;
}
.sidenavsetting {
  display: flex;
  align-items: center;
  align-content: flex-end;

  padding: 10px;
  /* color: #919191; */
  width: 100%;
}
.sidenavsetting1 {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  align-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
}
.sidenavsetting:hover {
  cursor: pointer;
  color: #1168df;
  background-color: #e6f2fd;
  border-radius: 10px;
  width: 100%;
}
.sidenavsetting2 {
  border-top: 1px solid #919191;
  width: 100%;
  padding: 5px 10px;
}

@media screen and (max-width: 1400px) {
  .sidenavbar {
    height: 100vh;
    bottom: 0;
    top: 0;
    width: 18%;
    overflow: scroll;
    font-size: 12px;
  }
  .sidenavheder > p {
    font-size: 26px !important;
    font-family:  "Anton", sans-serif !important;
  }
}
@media screen and (max-width: 1650px) {
  .sidenavbar {
    height: 100vh;
    bottom: 0;
    top: 0;
    width: 19%;
    overflow: scroll;
    font-size: 13px;
  }
  .sidenavheder > p {
    font-family:  "Anton", sans-serif !important;
    font-size: 28px !important;
  }
}
