body {
  margin: 0;
  color: #6a6f8c;
  background: #c8c8c8;
  font: 600 16px/18px "Open Sans", sans-serif;
}
.logo {
  padding: 8%;
  font-size: 38px;
  font-weight: bold;
}
.sing {
  font-size: 21px;
  /* font-weight: bold; */
}
.form {
  padding: 22% 6%;
}
.font-14 {
  font-size: 14px;
}
.loginButton {
  font-size: 16px !important;
  font-weight: bold !important;
  border: #2d6495 1px solid !important;
  border-radius: 22px !important;
  padding: 6px 20px !important;
}
.textFieldinput {
  padding: 3% !important;
}
.font-11 {
  font-size: 10px;
}

.lod {
  padding-left: 5px;
}
/* #dropdown1 .MuiFormLabel-root{
  margin: -6px 2px !important;
} */
.MuiLoadingButton-loadingIndicator {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1400px) {
  .loginButton {
    font-size: 12px !important;
    font-weight: bold !important;
    border: #2d6495 1px solid !important;
    border-radius: 22px !important;
    padding: 6px 20px !important;
  }
  .font-11 {
    font-size: 8px;
  }
  .font-14 {
    font-size: 10px;
  }
}
