.green {
  background-color: rgb(6, 241, 163);
  color: aquamarine;
  height: 200px;
}
.icon-border-right {
  border-right: 1px solid white;
  padding-right: 20px;
}
.up-arrow {
  position: relative;
  top: 23px;
  padding-left: 10px;
}
.company-text {
  padding: 8px 0 0 18px;
}
.icon-border-cofee {
  filter: invert(60%) sepia(1%) saturate(467%) hue-rotate(69deg) brightness(99%)
    contrast(87%);
  border-right: 1px solid #949494;
  width: auto;
  height: 83px;
  margin-left: 2px;
}
.cancel_ico {
  position: relative;
  left: 58px;
  top: -48px;
  z-index: 1;
}
.pointer-ico {
  height: 47px;
  filter: invert(61%) sepia(15%) saturate(3121%) hue-rotate(179deg)
    brightness(100%) contrast(88%);
}
.dot {
  width: 10px;
}
.tableHeder {
  padding: 10px 50px 13px 60px;
}
.table-text {
  background-color: #f7d6c3;
}
.bor {
  border-bottom: 1px solid gray;
}
.chart {
  box-sizing: border-box;
  height: 40v;
}
.highcharts-color-0 {
  fill: #dadada;
  stroke: #dadada;

  background-color: #949494;
}
.activerequest {
  padding-bottom: 2% !important;
  height: fit-content;
  margin-bottom: 100px;
}
input {
  background: #efefef;
  border: none;
}
.active-machines {
  padding-bottom: 14px;
}
.apexchert {
  height: 100% !important;
}

@media screen and (max-width: 1400px) {
  .overal-tea {
    font-size: 16px;
    margin: 0;
  }
  .fw-bolder {
    font-size: 15px !important;
  }
  .dateRange {
    font-size: 15px;
  }
  .up-arrow {
    top: 5px;
    font-size: 12px;
  }
  .company-text {
    font-size: 13px;
    margin: none;
  }
  .company-text1 {
    font-size: 13px;
    margin: none;
  }
  .cancel_ico {
    font-size: 22px !important;
    top: -45px;
  }
  .active-machines {
    padding-bottom: 14px;
  }
  .fw-bolder1 {
    padding-top: 10px;
    font-size: 12px;
  }
  .MasignIssues {
    font-size: 30px;
  }
  .dateRange > p {
    margin: 0;
  }
  .tableHeder {
    padding: 0px 18px;
  }
  .dashtbh > p {
    font-size: 17px !important;
  }
  .table-condensed {
    font-size: 12px;
  }
  .chartsize {
    font-size: 12px !important;
  }
  .chart {
    height: 300px !important;
  }
  .isueIcon {
    font-size: 12px;
  }

  /* .icon-border-right {
    font-size: 10%;
    padding-bottom: 10px;
  } */
}

@media screen and (max-width: 1650px) {
  .overal-tea {
    font-size: 16px;
    margin: 0;
  }
  .fw-bolder {
    font-size: 15px !important;
  }
  .dateRange {
    font-size: 15px;
  }
  .up-arrow {
    top: 5px;
    font-size: 12px;
  }
  .company-text {
    font-size: 13px;
    margin: none;
  }
  .company-text1 {
    font-size: 12px;
    margin: none;
  }
  .cancel_ico {
    font-size: 22px !important;
    top: -45px;
  }
  .active-machines {
    padding-bottom: 14px;
  }
  .fw-bolder1 {
    padding-top: 10px;
    font-size: 12px;
  }
  .MasignIssues {
    font-size: 30px;
  }
  .dateRange > p {
    margin: 0;
  }
  .tableHeder {
    padding: 0px 18px;
  }
  .dashtbh > p {
    font-size: 17px !important;
  }
  .table-condensed {
    font-size: 12px;
  }
  .chartsize {
    font-size: 12px !important;
  }
  .chart {
    height: 300px !important;
  }
  .isueIcon {
    font-size: 12px;
  }

  /* .icon-border-right {
    font-size: 10%;
    padding-bottom: 10px;
  } */
}

