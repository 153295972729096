@media screen and (max-width: 1400px) {
    .company-text {
      
      font-size: 10px;
    }
    .cart-value  {
      font-size: 24px !important;
    }
    .today-request{
        padding: 0 !important;
    }
  }
  #error-codes-color .MuiInput-input{
    color: red !important;
  }
  @media screen and (max-width: 1650px) {
    .company-text {
      
      font-size: 10px;
    }
    .cart-value  {
      font-size: 24px !important;
    }
    .today-request{
        padding: 0 !important;
    }
  }