.leadsForm{
    padding: 12px 40px;

}
.fieald-width{
    /* width: 90% !important; */
    padding: 5px 0px !important;

}
.border-radius-15{
    border-radius: 15px !important;
}
.leadsForm-all{
    width: 80% !important;
}
@media screen and (max-width: 500px) {
    .leadsForm{
        padding: 10px 12px;
    }
    .fieald-width{
        width: 100%;
    }
    .leadsForm-all{
        width: 100% !important;
    }
}
@media screen and (max-width: 1050px) {
    .leadsForm{
        padding: 10px 12px;
    }
    .fieald-width{
        width: 100%;
    }
    .leadsForm-all{
        width: 100% !important;
    }
}