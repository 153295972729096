.machine-master {
  box-sizing: border-box;
  background-color: #efefef;
}
.search-heder-table {
  padding-left: 1%;
  padding-right: 1%;
}
.export-excels{
  font-size: 15px;
}
#button-addon1 {
  color: #8f8f8f;
  outline: none;
  box-shadow: none;
}
.filter_active:hover {
  color: #8f8f8f;
}
.oopt {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.hedtable > tr {
  background-color: #f8f8f8 !important;
}
.bdr {
  border-radius: 66px;
  padding: 10px;
}
.hedtable > tr > th {
  text-align: center;
  padding: 23px;
}
.table1 > tbody > tr > td {
  text-align: center;
}

.table-ma {
  padding-bottom: 100px;
}
.table1 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  border-radius: 18px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  border-collapse: separate !important;
}
.table1::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.hedtable > tr > th,
.tbody > tr > td {
  border-left: solid #eeeeee 1px !important;
  border-top: solid #eeeeee 1px !important;
}

.hedtable > tr > :first-child,
.tbody > tr :first-child {
  border-left: none !important;
}
.tbody > tr > td {
  padding: 10px;
}
.hedtable > tr > th {
  border-top: none !important;
  background-color: #f8f8f8;
}

.tbody > tr:nth-child(even) {
  background-color: #fcfcfc;
}
.number-page {
  width: 13%;

}
.action {
  font-size: 20px;
  /* color: #9a9b9d; */
}
.tablesdatascroll{
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}
.tablesdatascroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
@media screen and (max-width: 1400px) {
  .hedtable > tr > th,
  .tbody > tr > td {
    font-size: 12px !important;
  }
  select {
    font-size: 12px !important;
  }
  input {
    font-size: 12px !important;
  }
  .export-excel {
    font-size: 12px !important;
    padding-top: 10px;
  }
  .export-excels{
    font-size: 12px;
  }
  .number-page {
    margin-top: 8px;
    width: 19%;
  }
  .ligh {
    height: 40px;
  }
  .input-group-table > input {
    height: 30px;
  }
}

@media screen and (max-width: 1650px) {
  .hedtable > tr > th,
  .tbody > tr > td {
    font-size: 13px !important;
  }
  select {
    font-size: 12px !important;
  }
  input {
    font-size: 12px !important;
  }
  .export-excel {
    font-size: 12px !important;
    padding-top: 10px;
  }
  .export-excels{
    font-size: 13px;
  }
  .number-page {
    margin-top: 8px;
    width: 19%;
  }
  .ligh {
    height: 40px;
  }
  .input-group-table > input {
    height: 30px;
  }
}

