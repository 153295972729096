.mainheder {
  height: auto;
  width: auto;
  /* float: right; */
  height: 82px;
}
.input-group > input,
.ligh {
  background-color: #efefef;
}
#button-addon1 {
  color: #8f8f8f;
}
.heder-badge {
  position: relative;
  bottom: 14px;
  left: 37px;
}
.search-hed {
  width: 50%;
  padding-left: 2%;
}
.input-group > input:focus {
  background-color: #efefef;
}
.form-control:focus {
  outline: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
}
.heder-logout {
  width: 100%;
  height: 100% ;
  margin-top: 12px !important;
  right: 10px !important;
  position: relative;
  /* margin-left: 20px !important; */
  border: 0 !important;
  background-color: #efefef !important;
  /* opacity: 0; */
}
.heder-logout > li {
  text-align: center;
}
.readablenotification{
  background-color: #efefef;
}


/* notifications */
.notification{
  width: 500px;
  position: absolute;
  z-index: 4;
  right: 35px;
}
.profile-menu{
  top: 0px !important;

  width: 200px;
  position: absolute;
  z-index: 4;
  /* right: 10px !important; */
}
.notification-scroll{
  max-height: 80vh;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

}
.notification-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
@media screen and (max-width: 1400px) {
  .mainheder {
    height: 70px;
    font-size: 12px;
  }
  .heder-logout {
    width: 80%;
  }
  .MuiTab-root{
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1650px) {
  .mainheder {
    height: 70px;
    font-size: 12px;
  }
  .heder-logout {
    width: 80%;
  }
  .MuiTab-root{
    font-size: 12px !important;
  }
}
