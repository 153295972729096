.viewtext {
  font-size: 30px;
}
.radias-14 {
  border-radius: 14px !important;
}
.op {
  font-size: 35px;
}
.actview {
  width: 90% !important;
}
.h {
  width: 100%;
}
.pad {
  padding-top: 10px;
}
.font-13{
  font-size: 15px;
}
.font-18{
  font-size: 17px;
}

@media screen and (max-width: 1400px) {
  .font-weight-bol {
    font-size: 15px !important;
    font-weight: bolder !important;
  }
  .viewtext {
    font-size: 18px !important;
  }
  .viewtext1 {
    font-size: 12px;
  }
  TextField {
    font-size: 12px !important;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-19 {
    font-size: 19px;
  }
  .search {
    height: 43px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
  }
  .MuiFormControlLabel-root {
    font-size: 12px !important;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    padding-bottom: 5px;
  }
  .addmachineform {
    width: 40% !important;
    background-color: red !important;
  }
  .MuiButton-root {
    font-size: 12px !important;
  }
  .font-13{
    font-size: 13px;
  }
  .font-18{
    font-size: 15px;
  }
}

@media screen and (max-width: 1650px) {
  .font-weight-bol {
    font-size: 15px !important;
    font-weight: bolder !important;
  }
  .viewtext {
    font-size: 18px !important;
  }
  .viewtext1 {
    font-size: 12px;
  }
  TextField {
    font-size: 12px !important;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-19 {
    font-size: 19px;
  }
  .search {
    height: 43px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
  }
  .MuiFormControlLabel-root {
    font-size: 12px !important;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    padding-bottom: 5px;
  }
  .addmachineform {
    width: 40% !important;
    background-color: red !important;
  }
  .MuiButton-root {
    font-size: 12px !important;
  }
  .font-13{
    font-size: 13px;
  }
  .font-18{
    font-size: 15px;
  }

}
