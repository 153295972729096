.machineForm {
  height: 100% !important;
  border-radius: 15px !important;
  padding: 40px 64px;
  
}
.machineForm1{
  height: 100% !important;
  border-radius: 15px !important;
  padding: 40px 64px;
  width: 600px;
}



@media screen and (max-width: 1400px){
  .font-weight-bol {
    font-size: 15px !important;
    font-weight: bolder !important;
  }
  .MuiInputLabel-animated{
    font-size: 12px;
  }
  // .machineForm1{
  //   width: 40%;
  //   height: 100% !important;
  // }
  .company-text1 {
    font-size: 13px;
    margin: none;
  }
  .cancel_ico {
    font-size:22px !important;
    top: -45px;
  }
  .active-machines {
    padding-bottom: 14px;
  }
  .fw-bolder1 {
    padding-top: 10px;
    font-size: 12px;
  }
  .MasignIssues {
    font-size: 30px;
  }
  TextField{
    font-size: 12px !important;
  }

}

@media screen and (max-width: 1650px){
  .font-weight-bol {
    font-size: 18px !important;
    font-weight: bolder !important;
  }
  .MuiInputLabel-animated{
    font-size: 12px;
  }
  // .machineForm1{
  //   width: 40%;
  //   height: 100% !important;
  // }
  .company-text1 {
    font-size: 13px;
    margin: none;
  }
  .cancel_ico {
    font-size: 22px !important;
    top: -45px;
  }
  .active-machines {
    padding-bottom: 14px;
  }
  .fw-bolder1 {
    padding-top: 10px;
    font-size: 12px;
  }
  .MasignIssues {
    font-size: 30px;
  }
  TextField{
    font-size: 12px !important;
  }

}