.drinkviewCardHead {
  height: 50px;
}
.viewDrinkHeadTable {
  background-color: #eeeeee;
}
.font-weight-bold{
  font-weight: bold;
}
.drinkbody {
  background-color: #f9f9f9;
}
.viewdrinktable {
  padding-right: 20px;
  height: 70%;
}
.viewDrinkHeadTable tr > th,
.drinkbody > tr > td {
  border-left: solid #e9e9e9 1px !important;
  border-top: solid #e9e9e9 1px !important;
  padding: 8px;
}
viewDrinkHeadTable > tr > :first-child,
.drinkbody > tr :first-child {
  border-left: none !important;
}
.view-tb {
  border: 1px solid #e9e9e9;
  height: 100%;
  /* background-color: #ffffff; */
  /* border-collapse: separate !important; */
}
.catagory-name {
  padding: 10px;
  font-weight: 400px;
  text-align: center;
  font-size: 35px;
}
.border-radias-14 {
  border-radius: 14px !important;
}

@media screen and (max-width: 1400px) {
  .catagory-name {
    font-size: 20px;
  }
  .drinkImage {
    height: 230px !important;
  }
  .drinkSubCatogory {
    font-size: 12px !important;
  }
  .drinkviewCardHead {
    font-size: 12px;
  }
  .viewDrinkHeadTable tr > th,
  .drinkbody > tr > td {
    font-size: 12px;
    padding: 5px;
  }
  .font-weight-bol {
    font-size: 15px !important;
    font-weight: bolder !important;
  }
  .viewtext {
    font-size: 18px !important;
  }
  .viewtext1 {
    font-size: 12px;
  }
  TextField {
    font-size: 12px !important;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-19 {
    font-size: 19px;
  }
  .search {
    height: 43px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
  }
  .MuiFormControlLabel-root {
    font-size: 12px !important;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    padding-bottom: 5px;
  }
  .addmachineform {
    width: 40% !important;
    background-color: red !important;
  }
  .MuiButton-root {
    font-size: 12px !important;
  }
  .font-20{
    font-size: 17px;
  }
  
}

@media screen and (max-width: 1650px) {
  .catagory-name {
    font-size: 20px;
  }
  .drinkImage {
    height: 230px !important;
  }
  .drinkSubCatogory {
    font-size: 13px !important;
  }
  .drinkviewCardHead {
    font-size: 13px;
  }
  .viewDrinkHeadTable tr > th,
  .drinkbody > tr > td {
    font-size: 13px;
    padding: 5px;
  }
  .font-weight-bol {
    font-size: 15px !important;
    font-weight: bolder !important;
  }
  .viewtext {
    font-size: 18px !important;
  }
  .viewtext1 {
    font-size: 13px;
  }
  TextField {
    font-size: 13px !important;
  }
  .font-12 {
    font-size: 12px !important;
  }
  .font-19 {
    font-size: 19px;
  }
  .search {
    height: 43px !important;
  }
  .MuiInputLabel-root {
    font-size: 13px !important;
  }
  .MuiFormControlLabel-root {
    font-size: 13px !important;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    padding-bottom: 5px;
  }
  .addmachineform {
    width: 40% !important;
    background-color: red !important;
  }
  .MuiButton-root {
    font-size: 13px !important;
  }
  .font-20{
    font-size: 16px;
  }
}
