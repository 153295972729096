.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}
.popup {
  padding: 0 62px 0px 80px !important;
  z-index: 1 !important;
}
.pop {
  z-index: 1;
  background-color: black;
}
.MuiDialog-paperScrollPaper {
  max-width: 100% !important;
  width: 1000px !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.font-20 {
  font-size: 20px;
}
